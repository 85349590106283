import { Collapse } from '../utilities/collapse';

const accordions = document.querySelectorAll('.accordion-faq');
accordions.forEach(accordion => {
  const header = accordion.querySelector('.accordion-faq__header');
  const body = accordion.querySelector('.accordion-faq__content');
  const collapse = new Collapse(body, 300);

  body.classList.add('collapse');

  header.addEventListener('click', () => {
    collapse.toggle();
    accordion.classList.toggle('is-open');
  });
});