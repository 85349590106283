import Swiper from 'swiper';
import { Pagination, EffectFade, Autoplay } from 'swiper/modules';

new Swiper('.side-block .swiper', {
  slidesPerView: 1,
  autoHeight: true,

  modules: [Pagination, EffectFade, Autoplay],

  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },

  autoplay: {
    delay: 3000,
  },

  pagination: {
    el: ".swiper-pagination",
    clickable: true
  },
});