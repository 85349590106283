import { StyleСlass } from "../base/settings";
import { header } from "../partials/header";

const mobile = document.querySelector('.mobile-menu');
const wrapper = mobile.querySelector('.mobile-menu__container');
const burger = header.querySelector('.header__burger .hamburger');
const nav = wrapper.querySelector('nav');

if (wrapper) {
  window.addEventListener('dropdownToggle', () => {
    const hasVerticalScrollbar = nav.scrollHeight > nav.clientHeight;

    if (hasVerticalScrollbar) wrapper.classList.add('mobile-menu__wrapper--scrollable');
    else wrapper.classList.remove('mobile-menu__wrapper--scrollable');
  });
}

burger.addEventListener('click', () => {
  document.body.classList.toggle(StyleСlass.body.overflow);
  header.classList.toggle(StyleСlass.header.mobile);
  mobile.classList.toggle(StyleСlass.mobile.open);
  burger.classList.toggle('is-active');
});