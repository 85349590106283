import { SetSize, MediaQuery } from "../base/functions";
import { StyleСlass, breakpoint } from "../base/settings";

const Background = () => {
  if (window.scrollY >= scrollOffset) header.classList.add(StyleСlass.header.background);
  else header.classList.remove(StyleСlass.header.background);
};

export const header = document.getElementById("header");
const top = header.querySelector('.header__top');
const bottom = header.querySelector('.header__bottom');

let lastScroll = 0;
const scrollOffset = (MediaQuery(breakpoint.md)) ? 5 : 100;

SetSize(header, "header", false);
const topHeight = SetSize(top, "header-top", false);

Background();

window.addEventListener('scroll', () => Background());
window.addEventListener('scroll', () => {
  if (window.scrollY >= 50) {
    const position = window.scrollY;
    if (position > lastScroll) {
      header.removeAttribute('style');
      SetSize(bottom, "header", false);
    }

    else {
      header.style.top = 0;
      const height = SetSize(header, "header", false);
    }

    lastScroll = position;
  }
});