export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const StyleСlass = {
  'header': {
    'background': 'header--background',
    'mobile': 'header--mobile',
  },

  "mobile": {
    "open": "mobile-menu--open",
  },

  "body": {
    "overflow": "disable-scroll",
  }
};

export const breakpoint = {
  'md': '(max-width: 800px)',
};

export const modalParams = {
  awaitCloseAnimation: true,
  disableFocus: true,
  disableScroll: true,

  onShow: modal => {
    window.currentModal = modal.id;
  },

  onClose: () => {
    window.currentModal = null;
  }
};