import MicroModal from "micromodal";
import { modalParams } from "../base/settings";

const cards = document.querySelectorAll('.card-feedback');
const modal = document.querySelector('#modal-feedback');
const modalContent = modal.querySelector('.modal__content');

cards.forEach(card => {
  const text = card.querySelector('.card-feedback__text');
  const action = card.querySelector('.card-feedback__action');

  action.addEventListener('click', () => {
    modalContent.innerHTML = text.innerHTML;
    MicroModal.show('modal-feedback', modalParams);
  });
});