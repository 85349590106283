import { CountUp } from 'countup.js';
import Swiper from 'swiper';

const digits = document.querySelectorAll('.achievement__digit');
digits.forEach(digit => {
  new CountUp(digit, digit.dataset.value,
    {
      enableScrollSpy: true,
      scrollSpyOnce: true,
      separator: ' ',
      duration: 3,
    })
    .start();
});


new Swiper('#clients-home .swiper', {
  slidesPerView: 2.5,
  spaceBetween: 16,

  breakpoints: {
    480: {
      spaceBetween: 30,
      slidesPerView: 3,
    },

    800: {
      slidesPerView: 4,
    },

    1200: {
      slidesPerView: 5,
    },

    1580: {
      slidesPerView: 6,
    },
  }
});

new Swiper('#feedback-home .swiper', {
  slidesPerView: 1.2,
  spaceBetween: 16,
  autoHeight: true,

  breakpoints: {
    800: {
      spaceBetween: 30,
      slidesPerView: 2,
    },

    1200: {
      slidesPerView: 3,
    },
  }
});